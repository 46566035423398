import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const WorkIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const projects = data.allMarkdownRemark.nodes.slice(0, 4)

  if (projects.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          There are currently no projects available. Please check back later for updates.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <div className="px-5 py-5 lg:px-0 lg:py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10">
          {projects.map(project => {
            const title = project.frontmatter.title || project.fields.slug

            return (
              <Link to={`/work${project.fields.slug}`} key={project.fields.slug} className="block">
                <div className="relative mb-4 w-full">
                {project.frontmatter.heroVideo ?
                    <video playsInline muted autoPlay loop className="">
                      <source src={project.frontmatter.heroVideo} type='video/mp4' />
                    </video>
                  :
                    project.frontmatter.imgSrc && (
                      <img
                        src={project.frontmatter.imgSrc}
                        alt={title}
                        className="w-full"
                      />
                    )
                  }
                  <div className="flex flex-col mt-3">
                    <h2 className="CyGroteskGrandBold">{title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: project.frontmatter.description || project.excerpt,
                      }} 
                      className="text-sm leading-5"
                    />
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
        <div className="flex justify-center mt-10">
          <Link to="/work" className="inline-block px-6 py-5 mb-5 text-black w-full CyGroteskGrandBold text-center bg-gray-300 hover:bg-black hover:text-white">
            See All Work
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default WorkIndex

export const Head = () => <Seo title="Engineering Manager Specializing in Generative AI, Digital Transformation, and eCommerce Innovation" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: ASC }
      filter: { fileAbsolutePath: { regex: "/content/projects/" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          imgSrc
          heroVideo
        }
      }
    }
  }
`